<template>
  <div class="container">
    <heading-block title="Certifications et Formations"></heading-block>
    <div class="flex flex-col md:flex-row flex-wrap justify-center">
      <div class="w-full md:w-1/3 pt-4 pb-4 flex flex-col items-center" v-for="(item, index) in items" :key="index">
        <a :href="item.download" :title="item.fullTitle ? item.fullTitle : item.title" target="_blank" rel="nofollow">
          <div class="circle bg-white rounded-full shadow-custom border-2 border-white flex items-center justify-center mb-4">
            <img :src="item.picture" :alt="item.title">
          </div>
        </a>
        <span class="mb-2">{{item.title}}</span>
        <span class="capitalize text-gray-600">{{ formatDate(item.year) }}</span>
      </div>
    </div>
  </div>

</template>

<script>
import HeadingBlock from './HeadingBlock.vue'
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';

export default {
  components: {
    HeadingBlock
  },
  name: 'CertificationTimeline',
  props: {
    items: Array
  },
   methods:  {
    formatDate: function (date) {
      return format(parseISO(date), 'MMMM yyyy',  {locale: fr});
    }
  }
}
</script>

<style lang="scss" scoped>
    .circle {
      height: 100px;
      width: 100px;

      img {
        max-height: 60px;
      }

      &:hover {
        transition: 1s;
        border: 3px solid #e9f0f5;
      }
    }
</style>
