<template>
    <div class="flex flex-col items-center text-center mb-8">
      <span class="bg-botticelli w-12 h-1 mb-4"></span>
      <h2 class="text-3xl font-extrabold text-gray-900 tracking-tight">
          {{ title }}
      </h2>
    </div>
</template>

<script>
export default {
  name: 'HeadingBlock',
  props: {
    title: String
  }
}
</script>
