<template>
  <header class="bg-gradient-to-r from-blue-600 via-blue-500 to-blue-600 px-4 sm:px-6 lg:px-16 relative">
    <div class="container">
      <div class="sm:pt-4 pb-10 sm:pb-14 flex flex-wrap items-center justify-center md:justify-between">
        <div class="text-left mt-10 w-full sm:w-1/2">
          <span class="tracking-wide uppercase text-botticelli text-opacity-75 font-bold text-lg xl:text-xl mb-4 opacity-75">{{ header.name }}</span>
          <h1 class="font-display text-white text-3xl leading-9 font-semibold sm:text-3xl sm:leading-10">{{ header.title }}</h1>
        </div>
        <div class="flex justify-start sm:justify-end mt-10 w-full sm:w-1/2">
          <div>
            <button v-clipboard="header.email"  v-clipboard:success="onSuccess" class="group flex mr-3">
              <div :class="{ 'bg-green-500 bg-opacity-100': emailIsInClipboard }" class="inline-flex items-center justify-center text-white font-medium bg-white bg-opacity-20 group-hover:bg-opacity-30 rounded-lg shadow-sm group-hover:shadow-lg py-3 px-5 border border-white border-opacity-10 transform group-hover:-translate-y-0.5 transition-all duration-150">
                <svg v-if="emailIsInClipboard" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white mr-3 text-opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white mr-3 text-opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
                </svg>
                {{ emailIsInClipboard ? 'Email copié' : 'Email' }}
              </div>
            </button>
          </div>
          <a :href="header.linkedin" target="_blank" rel="noopener" class="group flex">
            <div class="inline-flex items-center justify-center text-white font-medium bg-white bg-opacity-20 group-hover:bg-opacity-30 rounded-lg shadow-sm group-hover:shadow-lg py-3 px-5 border border-white border-opacity-10 transform group-hover:-translate-y-0.5 transition-all duration-150">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white mr-3 text-opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
              </svg>
              LinkedIn
            </div>
          </a>
        </div>
      </div>
    </div>
    <button @click="scrollToElement()" class="discover absolute bottom-0 left-1/2" aria-label="Voir mon parcours">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 bg-white shadow rounded-full text-dodger-blue hover:text-dodger-blue-dark" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z" clip-rule="evenodd" />
      </svg>
    </button>
  </header>
</template>

<script>
export default {
  name: 'HeaderBlock',
  props: {
    header: Object
  },
  data: function () {
    return {
      emailIsInClipboard: false
    }
  },
  methods: {
    scrollToElement() {
      const el = document.getElementById('mon-parcours');
  
      if (el) {
        el.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        });
      }
    },
    onSuccess() {
      this.emailIsInClipboard = true;
    },
  }
}

</script>

<style lang="scss" scoped>
 header {
   min-height: 200px;

   .discover {
     transform: translate(-50%, 50%);
     outline: 0;
   }
 }
</style>
