<template>
  <div class="container">
    <heading-block title="Expérience"></heading-block>
    <div class="exp relative py-8 flex flex-col items-center lg:block lg:mx-auto">
      <div class="timeline-block relative my-6 lg:my-16" v-for="(item, index) in items" :key="index">
        <div class="hidden lg:block timeline-circle absolute top-0 left-1/2 transform -translate-x-2/4 border-4 border-white rounded-full h-6 w-6" :style="{backgroundColor:item.color}"></div>
          <div class="timeline-content overflow-hidden flex flex-col bg-white lg:float-left p-4 shadow-custom rounded-lg relative -mt-5">
              <a :href="item.website" class="hidden sm:inline-block self-center mb-2" rel="noopener" :title="item.websiteTitle" target="_blank">
                <img :src="item.picture" :alt="item.title">
              </a>
              <h2 class="text-2xl font-semibold text-gray-800 tracking-tight pt-12 sm:pt-0 mb-2">{{item.title}}</h2>
              <p v-for="(item,index) in item.description" :key='index' class="pb-4 text-gray-700">
                <span v-html="item"></span>
              </p>
              <span class="date-mobile block lg:hidden text-gray-500">{{ formatDate(item.startDate) }} - {{ item.endDate != null ? formatDate(item.endDate) :  'Aujourd\'hui'  }}</span>
              <span class="hidden lg:block date float-left opacity-70 mt-5">{{ formatDate(item.startDate) }} - {{ item.endDate != null ? formatDate(item.endDate) :  'Aujourd\'hui'  }}</span>

            <div class="bg-mask z-0 block sm:hidden absolute w-full h-6 -top-10 left-0 opacity-20 shadow-custom">
               <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet" :style="{fill:item.color}">
                <path d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z" style="stroke: none;"></path>
                </svg>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadingBlock from './HeadingBlock.vue'
import { format, parseISO } from 'date-fns'
import { fr } from 'date-fns/locale'

export default {
  components: {
    HeadingBlock
  },
  name: 'VerticalTimeline',
  props: {
    items: Array
  },
  methods:  {
    formatDate: function (date) {
      return format(parseISO(date), 'dd MMMM yyyy',  {locale: fr});
    }
  }
}
</script>

<style lang="scss" scoped>
  .exp {
    .timeline-block {
        &:after {
          content: "";
          display: table;
          clear: both;
        }

        &:first-child {
          @apply mt-0;
        }

        &:last-child {
          @apply mb-0;
        }
      }

    .timeline-content {
        max-width: 500px;

        :not(.bg-mask) {
          @apply z-10;
        }

        &::after {
          content: "";
          display: table;
          clear: both;
        }


        .date {
          padding: .8em 0;
        }
    }

    .timeline-content
    .timeline-content img {
        height: 50px;
        margin: 0 auto;
        display: block;
    }

   @media only screen and (min-width: 1024px) {

      max-width: 1140px;
      &::before {
        content: '';
        width: 4px;
        @apply bg-botticelli absolute top-0 right-1/2 h-full transform translate-x-2/4;
      }

        .timeline-content {
            margin-left: 0;
            width: 45%;
            overflow: revert;
            &::before {
              content: '';
              position: absolute;
              top: 16px;
              right: 100%;
              height: 0;
              width: 0;
              border: 7px solid transparent;
              border-right: 7px solid white;
          }
        }
        .timeline-content::before {
            top: 24px;
            left: 100%;
            border-color: transparent;
            border-left-color: white;

        }

        .timeline-content .date {
            position: absolute;
            width: 100%;
            left: 118%;
            top: 6px;
        }

        .timeline-block:nth-child(even) .timeline-content::before {
            top: 24px;
            left: auto;
            right: 100%;
            border-color: transparent;
            border-right-color: white;
        }
        .timeline-block:nth-child(even) .timeline-content {
            float: right;
        }
        .timeline-block:nth-child(even) .timeline-content .date {
            left: auto;
            right: 118%;
            text-align: right;
        }

    }
  }
</style>
