<template>
    <div>
        <header-block :header="header"></header-block>
        <section id="mon-parcours" class="relative">
            <verticalTimeline :items="verticalTimeline"/>
            <div class="wiggle wiggle-left w-64 h-64"></div>
        </section>
        <section id="cv" class="relative">
            <div class="container">
                <horizontalTimeline :items="horizontalTimeline"/>
            </div>
            <div class="wiggle wiggle-right w-64 h-64"></div>
        </section>
        <section id="mes-certifications" class="relative">
            <certification-timeline :items="certificationTimeline"/>
            <div class="wiggle wiggle-left w-64 h-64"></div>
        </section>
        <section id="mes-competences" class="relative">
            <grid-skills :items="skills"/>
            <div class="wiggle wiggle-right w-64 h-64"></div>
        </section>
        <legal-notice :legal="legal"></legal-notice>
    </div>
</template>

<script>
import HeaderBlock from './components/HeaderBlock.vue'
import HorizontalTimeline from './components/HorizontalTimeline.vue'
import VerticalTimeline from './components/VerticalTimeline.vue'
import GridSkills from './components/GridSkills.vue'
import CertificationTimeline from './components/CertificationTimeline.vue'
import LegalNotice from './components/LegalNotice.vue'
import Assets from './assets.js';

export default {
    name: 'app',
    components: {
        GridSkills,
        HeaderBlock,
        HorizontalTimeline,
        VerticalTimeline,
        CertificationTimeline,
        LegalNotice,
    },
    data: function () {
        return {
            header: {
                name: 'Théo Delcey',
                title: 'Développeur Web Rémois',
                email: 'contact@theo-delcey.fr',
                linkedin: 'https://www.linkedin.com/in/theo-delcey/'
            },
            legal: Assets.PDFMentionsLegales,
            horizontalTimeline: [
                {
                    title: 'Lycée Roosevelt',
                    fullTitle: 'Lycée Franklin Roosevelt à Reims',
                    type: 'place'
                },
                {
                    title: 'BAC STI2D',
                    fullTitle: 'Baccalauréat: Sciences et Technologies de l\'Industrie et du Développement Durable',
                    year: '2015',
                    type: 'degree'
                },
                {
                    title: 'BTS SIO',
                    fullTitle: 'Brevet de Technicien Supérieur: Services Informatiques aux Organisations',
                    year: '2017',
                    type: 'degree'
                },
                {
                    title: 'IUT Reims',
                    fullTitle: 'Institut Universitaire de Technologie à Reims',
                    type: 'place'
                },
                {
                    title: 'LP S3IM',
                    fullTitle: 'Licence Professionnelle: Systèmes d’Information Industriels et Informatique Mobile',
                    year: '2018',
                    type: 'degree'
                }
            ],
            verticalTimeline: [
                {
                    title: 'Tipeee',
                    picture: Assets.LogoTipeee,
                    description: ['Développement d\'<a href=\'https://fr.tipeee.com/\' class=\'font-normal\' target=\'_blank\' rel=\'noopener\'>une plateforme</a> permettant aux créateurs de contenu et artistes de vivre de leur travail grâce au soutien de leur communauté.'],
                    website: 'https://fr.tipeee.com/',
                    websiteTitle: 'Tipeee - Artistes & Créateurs, vivez de votre passion',
                    color: '#d64758',
                    startDate: '2023-02-15',
                    endDate: null,
                },
                {
                    title: 'Henley IT',
                    picture: Assets.LogoHenley,
                    description: ['Développement de solutions interconnectées à destination des CSE (Comptabilité, gestion des activités, site internet, ...).'],
                    website: 'https://www.henley.fr/',
                    websiteTitle: 'L\'expert du logiciel CSE depuis plus de 10 ans',
                    color: '#f4c31a',
                    startDate: '2022-01-03',
                    endDate: '2022-06-17',
                },
                {
                    title: 'WiziFarm',
                    picture: Assets.LogoWiziFarm,
                    description: [
                        'Développement d\'<a href=\'https://mission.wizi.farm/\' class=\'font-normal\' target=\'_blank\' rel=\'noopener\'>une plateforme</a> de mise en relation des compétences pour le milieu agricole et viticole.',
                        'Participation à la mise en conformité RGPD.'
                    ],
                    website: 'https://wizi.farm/',
                    websiteTitle: 'WiziFarm, la simplicité d\'entreprendre',
                    color: '#b0ca40',
                    startDate: '2019-03-18',
                    endDate: '2021-12-01',
                },
                {

                    title: 'Servyr',
                    picture: Assets.LogoServyr,
                    description: ['Maintenance d\'une application permettant de simplifier la vie des courtiers et des assurés.'],
                    website: 'https://www.servyr.com/',
                    websiteTitle: 'Accueil - Servyr | Solutions d\'assurance pour votre entreprise',
                    color: '#ee7600',
                    startDate: '2018-07-23',
                    endDate: '2018-09-12'
                },
                {
                    title: 'Trèves',
                    picture: Assets.LogoTreves,
                    description: ['Continuation du projet commencé lors de mon précédent stage.'],
                    website: 'https://www.treves-group.com/',
                    websiteTitle: 'Trèves',
                    color: '#0d1b6f',
                    startDate: '2018-03-26',
                    endDate: '2018-07-13'
                },
                {
                    title: 'Trèves',
                    picture: Assets.LogoTreves,
                    description: ['Refonte d’un site web interne à l’entreprise utilisé quotidiennement par les commerciaux du monde entier permettant de gérer les appels d\'offres.'],
                    website: 'https://www.treves-group.com/',
                    websiteTitle: 'Trèves',
                    color: '#0d1b6f',
                    startDate: '2017-01-09',
                    endDate: '2017-02-10'
                },
                {
                    title: 'Chambre de Commerce et d\'Industrie',
                    picture: Assets.LogoCCI,
                    description: ['Refonte du site Pass’Apprentissage Entreprise, une plateforme web facilitant la mise en relation des jeunes avec les entreprises.'],
                    website: 'http://www.marne.cci.fr/',
                    websiteTitle: 'CCI Marne',
                    color: '#e50043',
                    startDate: '2016-05-23',
                    endDate: '2016-06-26'
                },
            ],
            certificationTimeline: [
                {
                    picture: Assets.LogoGoogle,
                    title: 'Marketing numérique',
                    fullTitle: 'Fondamentaux du marketing numérique',
                    year: '2018-02-01',
                    download: Assets.PDFGoogle
                },
                {
                    picture: Assets.LogoCnil,
                    title: 'L\'atelier RGPD',
                    year: '2019-03-01',
                    download: Assets.PDFCnil
                },
                {
                    picture: Assets.LogoGoogleAnalytics,
                    title: 'Google Analytics',
                    fullTitle: 'Analytics pour les débutants',
                    year: '2019-06-01',
                    download: Assets.PDFGoogleAnalytics
                },
                {
                    picture: Assets.LogoGoogleTagManager,
                    title: 'Google Tag Manager',
                    fullTitle: 'Principes de base de Google Tag Manager',
                    year: '2019-08-01',
                    download: Assets.PDFGoogleTagManager
                },
                {
                    picture: Assets.LogoUnumkey,
                    title: 'Initiation à la Cybersécurité',
                    fullTitle: 'Initiation à la Cybersécurité dans le développement web',
                    year: '2020-10-01',
                    download: Assets.PDFUnumkey
                }
            ],
            skills: [
                {
                    title: 'Bootstrap',
                    picture: Assets.LogoBootstrap,
                    website: 'https://getbootstrap.com/',
                    websiteTitle: 'Bootstrap · The most popular HTML, CSS, and JS library in the world',
                },
                {
                    title: 'Tailwind CSS',
                    picture: Assets.LogoTailwindCSS,
                    website: 'https://tailwindcss.com/',
                    websiteTitle: 'Tailwind CSS - A Utility-First CSS Framework for Rapidly Building Custom Designs',
                },
                {
                    title: 'Symfony',
                    picture: Assets.LogoSymfony,
                    website: 'https://symfony.com/',
                    websiteTitle: 'Symfony, High Performance PHP Framework for Web Development',
                },
                {
                    title: 'Stimulus',
                    picture: Assets.LogoStimulus,
                    website: 'https://stimulus.hotwired.dev/',
                    websiteTitle: 'Stimulus: A modest JavaScript framework for the HTML you already have.',
                },
                {
                    title: 'JavaScript',
                    picture: Assets.LogoJavaScript,
                    website: 'https://developer.mozilla.org/fr/docs/Web/JavaScript',
                    websiteTitle: 'JavaScript | MDN',
                },
                {
                    title: 'MySQL',
                    picture: Assets.LogoMySQL,
                    website: 'https://www.mysql.com/fr/',
                    websiteTitle: 'MySQL',
                },
                {
                    title: 'PostgreSQL',
                    picture: Assets.LogoPostgreSQL,
                    website: 'https://www.postgresql.org/',
                    websiteTitle: 'PostgreSQL: The world\'s most advanced open source database',
                },
                {
                    title: 'GitLab',
                    picture: Assets.LogoGitLab,
                    website: 'https://about.gitlab.com',
                    websiteTitle: 'The first single application for the entire DevOps lifecycle - GitLab',

                },
                {
                    title: 'PhpStorm',
                    picture: Assets.LogoPhpStorm,
                    website: 'https://www.jetbrains.com/phpstorm/',
                    websiteTitle: 'PhpStorm: Lightning-Smart IDE for PHP Programming by JetBrains',
                },
                {
                    title: 'Axeptio',
                    picture: Assets.LogoAxeptio,
                    website: 'https://www.axeptio.eu/',
                    websiteTitle: 'Axeptio - Solution de recueil de consentement',
                },
                {
                    title: 'Shortcut',
                    picture: Assets.LogoShortcut,
                    website: 'https://shortcut.com/',
                    websiteTitle: 'Project Management for Software Teams - Shortcut',
                },
                {
                    title: 'Zendesk',
                    picture: Assets.LogoZendesk,
                    website: 'https://www.zendesk.fr/',
                    websiteTitle: 'Logiciel de service client et CRM de ventes | Meilleurs logiciels 2020 par Zendesk.',
                }
            ]
        }
    },
}
</script>

<style lang="scss">
body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', serif;
    font-size: 16px;
    font-weight: 300;
    background-color: #e9f0f5;
}


body, html {
    height: 100%;
    width: 100%;
}

#app {
    overflow-x: hidden;
}

section {
    @apply my-12 px-4 sm:px-6 lg:px-16;
}


#mes-competences {
    min-height: 500px;
}

.wiggle {
    z-index: -1;
    transform-origin: 0 0;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");

    @apply hidden lg:block absolute opacity-60 transform -rotate-45;
    &.wiggle-left {
        top: 8rem;
        left: -10rem;
    }

    &.wiggle-right {
        bottom: 8rem;
        right: -6rem;
    }
}
</style>
