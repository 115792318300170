// Companies
import LogoTipeee from './assets/img/logo_tipeee.png';
import LogoHenley from './assets/img/logo_henley.png';
import LogoWiziFarm from './assets/img/logo_wizifarm.png';
import LogoTreves from './assets/img/logo_treves.png';
import LogoServyr from './assets/img/logo_servyr.png';
import LogoCCI from './assets/img/logo_cci.png';

// Certifications
import LogoGoogle from './assets/img/logo_google.png';
import LogoCnil from './assets/img/logo_cnil.png';
import LogoGoogleAnalytics from './assets/img/logo_google_an_alytics.png';
import LogoGoogleTagManager from './assets/img/logo_google_tag_manager.png';
import LogoUnumkey from './assets/img/logo_unumkey.png';

import PDFGoogle from './assets/pdf/delcey_theo_fondamentaux_du_marketing_numerique.pdf';
import PDFCnil from './assets/pdf/delcey_theo_attestation_atelier_rgpd.pdf';
import PDFGoogleAnalytics from './assets/pdf/delcey_theo_certification_analytics.pdf';
import PDFGoogleTagManager from './assets/pdf/delcey_theo_certification_tag_manager.pdf';
import PDFUnumkey from './assets/pdf/delcey_theo_attestation_cybersecurite_unumkey.pdf';

// Skills
import LogoStimulus from './assets/img/logo_stimulus.svg';
import LogoBootstrap from './assets/img/logo_bootstrap.png';
import LogoTailwindCSS from './assets/img/logo_tailwindcss.png';
import LogoSymfony from './assets/img/logo_symfony.png';
import LogoPhpStorm from './assets/img/logo_phpstorm.png';
import LogoMySQL from './assets/img/logo_mysql.png';
import LogoPostgreSQL from './assets/img/logo_postgresql.png';
import LogoGitLab from './assets/img/logo_gitlab.png';
import LogoJavaScript from './assets/img/logo_javascript.png';
import LogoAxeptio from './assets/img/logo_axeptio.png';
import LogoShortcut from './assets/img/logo_shortcut.png';
import LogoZendesk from './assets/img/logo_zendesk.png';

// Legal
import PDFMentionsLegales from './assets/pdf/mentions_legales.pdf';

export default {
    LogoTipeee,
    LogoHenley,
    LogoWiziFarm,
    LogoTreves,
    LogoServyr,
    LogoCCI,
    LogoGoogle,
    LogoCnil,
    LogoGoogleAnalytics,
    LogoGoogleTagManager,
    LogoUnumkey,
    LogoStimulus,
    LogoBootstrap,
    LogoTailwindCSS,
    LogoSymfony,
    LogoPhpStorm,
    LogoMySQL,
    LogoPostgreSQL,
    LogoGitLab,
    LogoJavaScript,
    LogoAxeptio,
    LogoShortcut,
    LogoZendesk,
    PDFGoogle,
    PDFCnil,
    PDFGoogleAnalytics,
    PDFGoogleTagManager,
    PDFUnumkey,
    PDFMentionsLegales,
}
