<template>
  <div class="container">
    <heading-block title="Compétences"></heading-block>
    <div class="flex flex-row flex-wrap justify-center">
      <div class="w-full md:w-2/4 lg:w-1/4 p-4 skill-item" v-for="(item, index)  in  items" :key="index">
        <a :href="item.website" rel="noopener" :title="item.websiteTitle" target="_blank">
          <div class="bg-white shadow-custom rounded p-4 flex flex-col items-center">
            <div class="mb-8">
              <img :src="item.picture" :alt="item.title" class="h-24">
            </div>
            <span class="font-medium tracking-wider leading-5 text-sm text-gray-500">{{item.title}}</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import HeadingBlock from './HeadingBlock.vue'

export default {
  components: {
    HeadingBlock
  },
  name: 'GridSkills',
  props: {
    items: Array,
  },
}
</script>
