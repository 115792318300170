<template>
<div>
  <heading-block title="Parcours scolaire"></heading-block>
  <div class="block lg:grid mx-auto">
    <div class="horizontal-timeline relative flex justify-between items-center mb-4 lg:mb-0" :class="cssClass(index+=1, index, item.type)" v-for="(item,index) in items" :key="index">
      <div class="flex items-center justify-between w-full">
        <template v-if="item.type == 'degree'">
          <span class="degree bg-dodger-blue-dark  text-white font-semibold rounded-full border-white border-2 px-4 py-2 mr-3" :title="item.fullTitle">
            {{ item.title }} <span class="text-botticelli text-sm">({{ item.year }})</span>
          </span>
          <span class="step-done text-green-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 bg-white rounded-full" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
          </span>
          </template>
          <template v-else>
            <span class="place bg-botticelli text-black font-semibold rounded-full border-white border-opacity-70 border-2 px-4 py-2 mr-3" :title="item.fullTitle">
              {{ item.title }}
            </span>
            <span class="step-place text-blue-400">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 bg-white rounded-full" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd" />
              </svg>
            </span>
          </template>
        </div>
    </div>
  </div>
</div>
</template>

<script>
import HeadingBlock from './HeadingBlock.vue'

export default {
  components: {
    HeadingBlock
  },
  name: 'HorizontalTimeline',
  props: {
    items: Array
  },
  methods: {
    cssClass: function(indexRow,indexCol, type) {
      let classes = `row-start-${indexRow} col-start-${indexCol}`
      if(type === 'degree') {
        classes +=  ` ml-4 lg:ml-0`
      }

      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
.horizontal-timeline {
  &:before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 50%;
    right:0;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    border: 2px dashed;
    @apply border-botticelli;
  }
}
</style>
