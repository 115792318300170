<template>
    <footer>
      <div class="container text-center pb-4">
        <a :href="legal" rel="nofollow">Mentions légales</a>
      </div>
    </footer>
</template>

<script>
  export default {
    name: 'LegalNotice',
    props: {
      legal: String
    }
  }
</script>
